import { Link } from "react-router-dom"
import { Button, Container, Divider, Header, Segment } from "semantic-ui-react"

function Home() {
  return (
    <Container text id="home">
      <Segment basic textAlign="center">
        <Segment size="huge" padded="very">
          <Header as="h1">Curation<br />Station</Header>
          <Header as="h2">Decentraland's Virtual Library</Header>
          <Divider hidden section />
          <Button color="black" size="massive" as={Link} to="//play.decentraland.org/?position=131%2C133" target="_blank">Play Now</Button>
        </Segment>
      </Segment>
    </Container>
  )
}

export default Home