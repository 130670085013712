import { BrowserRouter, Routes, Route, Navigate, NavLink } from 'react-router-dom'
import Home from './pages/Home'
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import { Menu, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import HowToPlay from './pages/HowToPlay';
import CourseCreators from './pages/CourseCreators';
import Blog from './pages/Blog';
import Leaderboard from './pages/Leaderboard';

function App() {
	return (
		<BrowserRouter>
			<Menu fixed='top'>
				<Menu.Menu position='left'>
					<Menu.Item as={NavLink} content="Curation Station" to="/" />
          <Menu.Item as={NavLink} content="Leaderboard" to="/leaderboard" />
          <Menu.Item as={NavLink} content="Articles" to="/articles" />
					<Menu.Item as={NavLink} content="How to Play" to="/how-to-play" />
          <Menu.Item as={NavLink} content="Course Creators" to="/course-creators" />
				</Menu.Menu>
				<Menu.Menu position='right'>
          <Menu.Item content="Play Now" as={Link} to="//play.decentraland.org/?position=131%2C133" target="_blank" />
					<Menu.Item content="Login" as={Link} to="#" />
				</Menu.Menu>
			</Menu>
			<Routes>
        <Route path='/how-to-play' Component={HowToPlay} />
				<Route path='/course-creators' Component={CourseCreators} />
        <Route path='/articles' Component={Blog} />
        <Route path='/leaderboard' Component={Leaderboard} />
				<Route path='/' Component={Home} />
			</Routes>
			<Menu fixed='bottom' size="small" borderless secondary inverted>
        <Menu.Item position="left">
          &copy; 2023 DCL Curations
        </Menu.Item>
        <Menu.Menu position='right'>
            <Menu.Item as={Link} to='//dclcurations.com' target='_blank'>
              <Icon name='globe' size="large" />
            </Menu.Item>
            <Menu.Item as={Link} to='//twitter.com/DCLCurations' target='_blank'>
              <Icon name='twitter' size="large" />
            </Menu.Item>
            <Menu.Item as={Link} to='//www.youtube.com/c/DCLCurations' target='_blank'>
              <Icon name='youtube' size="large" />
            </Menu.Item>
            <Menu.Item as={Link} to='//medium.com/@dclcurations' target='_blank'>
              <Icon name='medium' size="large" />
            </Menu.Item>
        </Menu.Menu>
      </Menu>
		</BrowserRouter>
	);
}

export default App

