import { Link } from "react-router-dom"
import { Button, Container, Divider, Embed, Header, Segment } from "semantic-ui-react"

import thumbnail1 from '../images/thumbnails/creators1.jpg'
import thumbnail2 from '../images/thumbnails/creators2.jpg'

function CourseCreators() {
  return (
      <Container text>
        <Segment size="large" padded>
          <Header as="h1">Course Creators</Header>
          <Divider />
          <Embed
            id="810750069"
            placeholder={thumbnail1}
            source="vimeo" />
          <Divider />
          <Embed
            id="810750080"
            placeholder={thumbnail2}
            source="vimeo" />
          {/* <Divider hidden section />
          <Button size="massive" as={Link} to="//play.decentraland.org/?position=131%2C133" target="_blank" color="purple">Play Now</Button> */}
        </Segment>
      </Container>
  )
}

export default CourseCreators