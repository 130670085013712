import { Link } from "react-router-dom"
import { Button, Container, Divider, Header, Segment } from "semantic-ui-react"

function HowToPlay() {
  return (
    <Container text>
      <Segment size="large" padded>
        <Header as="h1">How to Play</Header>
        <Divider />
        <ul>
          <li>When you first enter the Curation Station located at (130, 130) Decentraland, you will notice a Book Portal at the roadside entrance which will teleport you to the Virtual Library</li>
          <li>New users need to speak with the Librarian at the front desk to receive 6 initial Course Credits (CCs)</li>
          <li>These can be used to unlock the first 3 courses on the Library Card (located under your mini map on the left)</li>
          <li>Click once on a course to unlock it. The, click again to start the course</li>
          <li>Once started, look around the Library for a floating green arrow that indicates the shelf where your course is located</li>
          <ul>
            <li>Walk over, pickup the book, and bring it to the quiz booth in the center to play the video</li>
            <li>At the end of each video, you will be given a quiz which, if completed successfully, will award uses with Intelligence Points that can be exchanged for NFT wearables with in-game bonuses</li>
          </ul>
          <li>Completing the first 3 courses will explain how the library, course credits, intelligence points, questing, and wearable exchange work</li>
          <li>After completion, return to the Librarian to unlock the remainder of courses, questing, and the wearable room!</li>
        </ul>
      </Segment>
      <Segment size="large" padded>
        <Header as="h1">Rewards</Header>
        <Divider />
        <ul>
          <li>Courses 1-2: 1 Intelligence Point each</li>
          <li>Courses 3-4: 2 Intelligence Points each</li>
          <li>Course 5: 3 Intelligence Points</li>
        </ul>
      </Segment>
      <Segment size="large" padded>
        <Header as="h1">Notes</Header>
        <Divider />
        <ul>
          <li>The first course in a series costs 1 CC to unlock, with the cost increasing by 1 CC for each next course up to 5</li>
          <li>Scissors will only spawn 1 time, after that you will need to spend CCs to repair them by talking to the Library Assistant in the Questing Room</li>
        </ul>
      </Segment>
    </Container>
  )
}

export default HowToPlay