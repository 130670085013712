import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { Card, Container, Divider, Image, Header, Segment, Icon, Label } from "semantic-ui-react"

function Blog() {
  const [items, setItems] = useState(null)

  useEffect(() => {
    // Update the document title using the browser API

    const url = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@dclcurations"

    const fetchData = async () => {
      try {
        const response = await fetch(url)
        const json = await response.json()
        if (json.status === "ok") {
          setItems(json.items)
          console.log("HERE", json)
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    if (items === null) {
      fetchData()
    }
  }, [items])

  return (
      <Container>
        <Segment size="large" padded>
          <Header as="h1">Curation Station Articles</Header>
          <Divider />
          <Card.Group itemsPerRow={2} stackable>
          {items && items.map((item) => {
            return (
              <Card key={item.pubDate} as={Link} to={item.link} target="_blank">
                <Image src={item.thumbnail} wrapped ui={false} />
                <Card.Content>
                <Card.Header>{item.title}</Card.Header>
                <Card.Description>
                {new Date(item.pubDate).toDateString()}  
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Label.Group>
                  {item.categories.map((category) => {
                    return (
                      <Label key={category} content={category} size="large" />
                    )
                  })}
                </Label.Group>
              </Card.Content>
              </Card>
            )
          })}
          </Card.Group>
          {/* <Divider hidden section />
          <Button size="massive" as={Link} to="//play.decentraland.org/?position=131%2C133" target="_blank" color="purple">Play Now</Button> */}
        </Segment>
      </Container>
  )
}

export default Blog