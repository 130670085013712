


import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { Card, Container, Divider, Image, Header, Segment, Icon, Label, Table } from "semantic-ui-react"

function Leaderboard() {
  const [data, setData] = useState(null)

  useEffect(() => {  
    if (data === null) {
      const url = "wss://dcl-library-backend-api.dappcraft.io?realm=loki&room=peer-wc11k7y&user_id=0x0000000000000000000000000000000000000000"
      const ws = new WebSocket(url)

      ws.onmessage = evt => {
        const message = JSON.parse(evt.data)
        if( message.route === "leader_boards/update_leader_board" ) {
          ws.close()
          setData(message.payload.leader_boards)
        }
      }
    }
  }, [])

  function listData(list) {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Rank</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Points</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.slice(0,10).map(item=>{
            return (
              <Table.Row key={item.position}>
                <Table.Cell>{item.position + 1}</Table.Cell>
                <Table.Cell>{item.nickname}</Table.Cell>
                <Table.Cell>{item.points}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
        
      </Table>
    )
  }

  return (
      <Container text>
        <Segment size="large" padded>
          <Header as="h1">Curation Station Leaderboard</Header>
          <Divider />
          <Header as="h2">Most Books Filed</Header>
          { data && listData(data.books_filled.array)}
          <Header as="h2">Most Books Repaired</Header>
          { data && listData(data.books_repaired.array)}
          <Header as="h2">Most Courses Completed</Header>
          { data && listData(data.courses_completed.array)}
        </Segment>
      </Container>
  )
}

export default Leaderboard